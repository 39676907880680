// extracted by mini-css-extract-plugin
export var alignLeft = "r_ps d_fl d_bC d_dr";
export var alignCenter = "r_bL d_fm d_bz d_ds";
export var alignRight = "r_pw d_fn d_bD d_dt";
export var menuContainer = "r_q4 d_dS";
export var menuContainerFull = "r_q5 d_dQ";
export var menuMainHeader = "r_jz d_jz d_s d_c0";
export var menuComponentWrapper = "r_jD d_jD d_cr";
export var menuComponentWrapperDesign2 = "r_jF d_jF d_cv";
export var menuComponentText = "r_jB d_jB d_ck";
export var menuComponentTextDesign2 = "r_jC d_jC d_cp";
export var menuImageWrapperDesign2 = "r_jG d_jG d_s d_W";