// extracted by mini-css-extract-plugin
export var alignDiscLeft = "m_pr d_fl d_bC d_dr";
export var alignLeft = "m_ps d_fl d_bC d_dr";
export var alignDiscCenter = "m_pt d_fm d_bz d_ds";
export var alignCenter = "m_bL d_fm d_bz d_ds";
export var alignDiscRight = "m_pv d_fn d_bD d_dt";
export var alignRight = "m_pw d_fn d_bD d_dt";
export var footerContainer = "m_px d_dS d_bS";
export var footerContainerFull = "m_py d_dQ d_bS";
export var footerHeader = "m_kc d_kc";
export var footerTextWrapper = "m_pz d_s";
export var footerDisclaimerWrapper = "m_kk d_kk d_cg";
export var badgeWrapper = "m_pB d_s d_bw d_bL d_bB d_bF";
export var footerDisclaimerRight = "m_kl d_kl d_bw";
export var footerDisclaimerLeft = "m_km d_km d_bw";
export var verticalTop = "m_pC d_bw d_bF d_bK d_bH";
export var firstWide = "m_pD";
export var disclaimer = "m_pF";
export var socialDisclaimer = "m_pG";
export var left = "m_pH";
export var wide = "m_pJ d_cv";
export var right = "m_pK d_bG";
export var line = "m_fg d_fh d_cr";
export var badgeDisclaimer = "m_pL d_by d_bL d_bF";
export var badgeContainer = "m_pM d_bw d_bD d_bL";
export var badge = "m_pN";
export var padding = "m_pP d_c4";
export var end = "m_pQ d_bD";
export var linkWrapper = "m_pR d_dx";
export var link = "m_mF d_dx";
export var colWrapper = "m_pS d_ct";
export var media = "m_pT d_bt d_dv";
export var itemRight = "m_pV";
export var itemLeft = "m_pW";
export var itemCenter = "m_pX";
export var exceptionWeight = "m_pY t_rK";