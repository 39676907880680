// extracted by mini-css-extract-plugin
export var articleWrapper = "p_p6";
export var articleText = "p_p7 d_dr d_cp";
export var header = "p_p8 d_W d_s";
export var headerImageWrapper = "p_p9 d_9 d_X d_5 d_4 d_1 d_6 d_bg d_bN";
export var headerGradient = "p_qb d_9 d_X d_5 d_4 d_1 d_6";
export var headerGradientOverlay = "p_qc d_9 d_X d_5 d_4 d_1 d_6";
export var headerContentWrapper = "p_qd d_bw d_bL d_bz d_bF d_bh d_W";
export var contentWrapper = "p_mC d_s";
export var dateTag = "p_qf d_cb d_cn d_dm d_s";
export var icon1 = "p_qg d_cL";
export var icon2 = "p_qh d_cL d_cF";
export var tagBtn = "p_qj d_bY d_cz d_dl d_dx";
export var headerText = "p_qk d_s d_cb d_cp d_bS";
export var center = "p_ql d_ds";
export var videoIframeStyle = "p_qm d_d2 d_s d_D d_bv d_bY d_N";
export var articleImageWrapper = "p_qn d_cp d_W";
export var articleImageWrapperIcon = "p_qp d_cp d_W";
export var articleRow = "p_qq d_bz d_bb";
export var quoteWrapper = "p_gN d_by d_bL d_cp";
export var quoteBar = "p_qr d_D";
export var quoteText = "p_qs";
export var authorBox = "p_qt d_s";
export var authorRow = "p_qv d_bz d_bS d_cv";
export var separator = "p_qw d_s";
export var line = "p_fg d_s d_fg d_cV d_cv";
export var authorImage = "p_lr d_b4 d_W d_s d_cq";
export var authorText = "p_qx d_cd d_cq";
export var masonryImageWrapper = "p_qy";
export var bottomSeparator = "p_qz d_s d_cv";
export var linksWrapper = "p_qB d_cd d_dr";
export var comments = "p_qC d_bz d_cv";
export var sharing = "p_qD d_bw d_bz d_bF";
export var shareText = "p_qF d_s d_ds";
export var icon = "p_qG";
export var text = "p_qH";
export var SubtitleSmall = "p_qJ";
export var SubtitleNormal = "p_qK";
export var SubtitleLarge = "p_qL";