// extracted by mini-css-extract-plugin
export var lbContainerOuter = "w_sG";
export var lbContainerInner = "w_sH";
export var movingForwards = "w_sJ";
export var movingForwardsOther = "w_sK";
export var movingBackwards = "w_sL";
export var movingBackwardsOther = "w_sM";
export var lbImage = "w_sN";
export var lbOtherImage = "w_sP";
export var prevButton = "w_sQ";
export var nextButton = "w_sR";
export var closing = "w_sS";
export var appear = "w_sT";